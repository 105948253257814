<template>
	<section class="activity-details" :key="id">
		<div class="max-width-1000">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item to="/activity">最新活動</el-breadcrumb-item>
				<el-breadcrumb-item>{{_activity.title}}</el-breadcrumb-item>
			</el-breadcrumb>
			<br>
			<h1>{{_activity.title}}</h1>
			<h2>{{_activity.sub_title}}</h2>
			<hr>
			<template v-if="$root.rwd < 3 && _activity.banners">
				<br>
				<carousel :options="{ interval:5000, duration:1000, loop:true }" control indicator>
					<template v-for="({url}, i) in _activity.banners">
						<img :src="url" width="100%" :key="i">
					</template>
				</carousel>
			</template>
			<template v-else-if="_activity.banners_mobile">
				<br>
				<carousel :options="{ interval:5000, duration:1000, loop:true }" control indicator>
					<template v-for="({url}, i) in _activity.banners_mobile">
						<img :src="url" width="100%" :key="i">
					</template>
				</carousel>
			</template>
			<template v-if="youtubeId">
				<br>
				<div class="youtube">
					<iframe :src="`https://www.youtube.com/embed/${youtubeId}`" frameborder="0" allowfullscreen />
				</div>
			</template>
			<br>
			<h3 class="theme_text">演出時間：
				<template v-if="_activity.perform_dates && _activity.perform_dates.length">
					{{_activity.perform_dates[0] | dateFilter}} ~ {{_activity.perform_dates[1] | dateFilter}}
				</template>
				<br>演出地點：{{_activity.location}}
				<br>票價：{{_activity.price}}
			</h3>
			<div v-html="_activity.details" />
			<template v-if="_activity.link">
				<br>
				<div class="center">
					<a :href="_activity.link" target="_blank">
						<el-button type="theme" round>前往購票</el-button>
					</a>
				</div>
			</template>
			<br>
			<br>
		</div>
	</section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import querystring from "querystring";
export default {
	components: {
		Carousel: () => import("@/components/carousel"),
		Thumb: () => import("@/components/thumb"),
	},
	props: ["id"],
	computed: {
		...mapState("activity", ["_activity"]),
		youtubeId() {
			const { youtube } = this._activity;
			return youtube && querystring.parse(new URL(youtube).searchParams.toString())["v"] || ""
		}
	},
	watch: {
		id(val) {
			this.init();
		}
	},
	methods: {
		...mapActions("activity", ["_getData"]),
		async init() {
			await this._getData(this.id);
		}
	},
	created() {
		this.init();
	}
}
</script>

<style lang="scss" scoped>
.activity-details {
	padding: 50px 5%;
	position: relative;
	background-size: 100%;
	background-repeat: no-repeat;
	background-image: url("../../../assets/background/brush2.png"),
		url("../../../assets/background/dot1.png"),
		url("../../../assets/background/dot2.png");
	background-position: center 70%, center 80%, center 80%;
}
</style>